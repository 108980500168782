<template>
  <section>
    <ul class="authorization-social">
      <li>
        <v-btn
          class="mat-link facebookBtn"
          @click="logInWithFacebook"
          v-if="!loadingFb"
        >
          <img :src="require('@/assets/images/facebook.svg')" alt />
          <span class="textnoresponsive">Facebook</span>
        </v-btn>
        <v-btn class="mat-link facebookBtn" loading v-if="loadingFb"> </v-btn>
      </li>
      <li>
        <!-- <GoogleLogin
          :params="params"
          :onSuccess="onSuccess"
          :onFailure="onFailure"
        > -->
        <v-btn class="mat-link googleBtn" @click="googleSignIn" v-if="!loading">
          <img :src="require('@/assets/images/google.svg')" alt />
          <span class="textnoresponsive">LOGIN WITH GOOGLE</span>
        </v-btn>
        <v-btn class="mat-link googleBtn" loading v-if="loading"> </v-btn>

        <!-- </GoogleLogin> -->
      </li>
    </ul>
  </section>
</template>

<script>
// import GoogleLogin from "vue-google-login";
import firebase from "firebase";
import { mapActions } from "vuex";
export default {
  name: "SocialLogin",
  components: {
    // GoogleLogin,
  },
  data() {
    return {
      params: {
        client_id:
          "129953055617-vlu826sdbh32dpe35ueoacmj9rltvbma.apps.googleusercontent.com",
      },
      loading: false,
      loadingFb: false,
    };
  },
  methods: {
    ...mapActions(["postSocialLogin", "postWishlist"]),

    async logInWithFacebook() {
      // Create an instance of the Facebook provider object:
      var firebaseConfig = {
        apiKey: "AIzaSyCdZUEgbrTcN_rjJswKB91PK5EXR0K9E54",
        authDomain: "evsteer-29f78.firebaseapp.com",
        projectId: "evsteer-29f78",
        storageBucket: "evsteer-29f78.appspot.com",
        messagingSenderId: "736579376608",
        appId: "1:736579376608:web:038815a41e3e7d8a908785",
        measurementId: "G-ZB7VTP7051",
      };
      firebase.initializeApp(firebaseConfig);
      var provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          /** @type {firebase.auth.OAuthCredential} */
          var credential = result.credential;
          // This gives you a Facebook Access Token. You can use it to
          // access the Facebook API.

          let socialData = {
            socialLogin: "facebook",
            accessToken: credential.accessToken,
            fullname: result.user.displayName,
            email: result.user.email,
          };
          this.loadingFb = true;
          await this.postSocialLogin(socialData);

          if (sessionStorage.getItem("wishlist")) {
            await this.postWishlist({
              productId: sessionStorage.getItem("wishlist"),
            });
            this.$router.push(`/product/${sessionStorage.getItem("wishlist")}`);
            sessionStorage.removeItem("wishlist");
          } else {
            if (!sessionStorage.getItem("product")) {
              this.$router.push("/");
            } else {
              this.$router.push(
                `/product/${sessionStorage.getItem("product")}`
              );
              sessionStorage.removeItem("product");
            }
          }
          this.loadingFb = false;
        })
        .catch((error) => {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          // The email of the user's account used.
          var email = error.email;
          // The firebase.auth.AuthCredential type that was used.
          var credential = error.credential;
          console.log(errorCode, errorMessage, email, credential);
        });
    },

    googleSignIn() {
      var firebaseConfig = {
        apiKey: "AIzaSyCdZUEgbrTcN_rjJswKB91PK5EXR0K9E54",
        authDomain: "evsteer-29f78.firebaseapp.com",
        projectId: "evsteer-29f78",
        storageBucket: "evsteer-29f78.appspot.com",
        messagingSenderId: "736579376608",
        appId: "1:736579376608:web:038815a41e3e7d8a908785",
        measurementId: "G-ZB7VTP7051",
      };

      firebase.initializeApp(firebaseConfig);

      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(async (result) => {
          let socialData = {
            socialLogin: "google",
            accessToken: result.credential.accessToken,
            fullname: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
          };
          this.loading = true;
          await this.postSocialLogin(socialData);

          if (sessionStorage.getItem("wishlist")) {
            await this.postWishlist({
              productId: sessionStorage.getItem("wishlist"),
            });
            this.$router.push(`/product/${sessionStorage.getItem("wishlist")}`);
            sessionStorage.removeItem("wishlist");
          } else {
            if (!sessionStorage.getItem("product")) {
              this.$router.push("/");
            } else {
              this.$router.push(
                `/product/${sessionStorage.getItem("product")}`
              );
              sessionStorage.removeItem("product");
            }
          }
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  created() {
    window.fbAsyncInit = function () {
      this.FB.init({
        appId: "3516614005029920",
        cookie: true,
        xfbml: true,
        version: "v8.0",
      });

      this.FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
};
</script>

<style lang="scss" scoped>
.authorization {
  &-social {
    list-style: none;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    padding-left: 0;
    margin-bottom: 20px;
    li {
      margin-right: 10px;
      .v-btn {
        box-shadow: none;
        border: 1px solid #000;
        border-radius: 0;
        font-size: 14px;
        border-radius: 5px;
        min-height: 40px;
      }
      img {
        margin-right: 20px;
        @media only screen and (max-width: 991px) {
          margin-right: 0;
        }
      }
    }
  }
}
.textnoresponsive {
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.facebookBtn {
  background-color: #3b5998 !important;
  color: #fff !important;
}
.googleBtn {
  background-color: #fff !important;
  color: #000 !important;
}
</style>