<template>
  <section class="loginBox">
    <v-form ref="loginForm">
      <v-text-field
        v-model="email"
        outlined
        placeholder="Email"
        :rules="emailRules"
      ></v-text-field>
      <v-text-field
        v-model="password"
        outlined
        placeholder="Password"
        :append-icon="showpassword ? 'mdi-eye' : 'mdi-eye-off'"
        :type="showpassword ? 'text' : 'password'"
        @click:append="showpassword = !showpassword"
        :rules="[rules.required]"
      ></v-text-field>
      <div class="d-flex align-center justify-space-between">
        <v-checkbox label="Remember me"> </v-checkbox>
        <router-link to="/forgetpassword"> Forget Password ? </router-link>
      </div>
      <v-btn loading v-if="loading"></v-btn>
      <v-btn large class="viewMore mb-3" v-if="!loading" @click="validate"
        >Login <v-icon> mdi-chevron-right </v-icon
        ><v-icon> mdi-chevron-right </v-icon></v-btn
      >
    </v-form>
    <div class="mt-3">
      Or Log In With
      <SocialLogin />
    </div>
    <div class="mt-3">
      Not Registered yet?
      <router-link to="/registration"> Create an Account </router-link>
    </div>
    <v-snackbar v-model="snackbar" :color="color"> {{ message }}</v-snackbar>
    <div class="fixedVerify" v-if="allVerifyPopup">
      <OTPVerify />
    </div>
  </section>
</template>

<script>
import OTPVerify from "@/components/user/authorization/OTPVerify";
import { mapActions, mapGetters } from "vuex";
import SocialLogin from "@/components/user/authorization/SocialLogin";
export default {
  name: "LoginForm",
  components: {
    OTPVerify,
    SocialLogin,
  },
  data: () => ({
    email: "",
    password: "",
    showpassword: false,
    loading: false,
    snackbar: false,
    message: "",
    color: "black",
    emailRules: [(v) => !!v || "Email is required."],
    rules: {
      required: (value) => !!value || "Password is required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    ...mapActions(["postLogin", "postWishlist"]),
    async validate() {
      if (this.$refs.loginForm.validate()) {
        this.loading = true;
        let data = {};
        data.email = this.email;
        data.password = this.password;
        await this.postLogin(data);
        if (this.allLogin.status == 200) {
          if (sessionStorage.getItem("wishlist")) {
            await this.postWishlist({
              productId: sessionStorage.getItem("wishlist"),
            });
            this.$router.push(`/product/${sessionStorage.getItem("wishlist")}`);
            sessionStorage.removeItem("wishlist");
          } else {
            if (!sessionStorage.getItem("product")) {
              this.$router.push("/");
            } else {
              this.$router.push(
                `/product/${sessionStorage.getItem("product")}`
              );
              sessionStorage.removeItem("product");
            }
          }
        } else {
          this.snackbar = true;
          this.message = this.allLogin.data.message;
          this.color = "red";
        }
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allLogin", "allVerifyPopup", "allRegister"]),
  },
  created() {
    if (this.allRegister.status == 200) {
      this.snackbar = true;
      this.message = "Please Login Now";
    } else {
      this.snackbar = true;
      this.message = "Please Login Now";
    }
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100% !important;
  background: #0878d4 !important;
  min-height: 55px;
}
.socialBtn {
  list-style: none;
  padding-left: 0;
  display: flex;
  margin: 15px 0;
  li {
    margin-right: 10px;
  }
  .fb {
    background: #3b5998 !important;
  }
  .gp {
    background: #d34836 !important;
  }
  .v-btn {
    min-width: 20px !important;
    width: 55px !important;
    min-height: 50px;
    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }
}
.v-btn {
  color: #fff;
}
</style>