<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="6" class="leftBack" cols="12">
          <img src="@/assets/images/authenticatePage.png" alt="" />
        </v-col>
        <v-col md="6" class="rightBox" cols="12">
          <div class="interBox">
            <h1 class="systemTitle">Log In</h1>
            <Login />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>


<script>
import Login from "@/components/user/authorization/Login";
export default {
  name: "Register",
  components: {
    Login,
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.leftBack {
  background: #e0eaf2;
  min-height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    padding: 20px 40px;
    width: 80%;
  }
}
.systemTitle{
  font-size: 35px;
}
.rightBox {
  display: flex;
  align-items: center;
  padding: 50px;
  .interBox {
    width: 100%;
  }
}
</style>